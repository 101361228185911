<div>
  <img src="../../assets/dkinsurance_front_view.jpg">
</div>
<mat-card>
  <mat-card-title
    >Could You Get Better Insurance Coverage at a Lower Rate?</mat-card-title
  >
  <aside style="padding: 1em">
    <i>Speak with Dawn Koehler today to find out!</i>
  </aside>
  <mat-card-content>
    <p>
      Insurance is a necessity for nearly every aspect of your life, from your
      car to your home and family - but finding an effective, affordable policy
      can be difficult. While there are many big-name insurance agencies out
      there, few can provide the flexibility and attentiveness you need while
      attempting to find the best rate available.
    </p>
    <p>
      As an independent insurance agent. Dawn Koehler can present you with many
      coverage options and help you secure the one that is best for you and your
      budget. Since she's not restricted by a particular company's policies and
      sales goals. Dawn Koehler can work specifically to meet the needs of her
      clients, not the quotas of the big insurance companies. To partner with an
      independent insurance agent for all of your insurance needs, get in touch
      with us.
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Enjoy attentive, accessible personal service</mat-card-title>
  <aside style="padding: 1em">
    <i>An independent agent can provide service big companies simply cannot</i>
  </aside>
  <mat-card-content>
    <p>
      Having an insurance company treat you like a number can be frustrating,
      especially when you have questions or need to file a claim. At Dawn
      Koehler Insurance Agency, we are accessible when you need us, providing
      the timely, attentive service you deserve.
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title
    >Speak with an agent today about your coverage needs</mat-card-title
  >
  <aside style="padding: 1em">
    <i>Find the policy that is best for you</i>
  </aside>
  <mat-card-content>
    <p>
      Make Dawn Koehler Insurance Agency your trusted provider for personal and
      commercial insurance plans in Great Bend, PA and the surrounding areas.
      Licensed in Pennsylvania and New York, Dawn Koehler can find you the best
      coverage and rates on policies including ...
    </p>
    <ul>
      <li>
        <b>Auto</b> comprehensive, liability, collision, uninsured/underinsured
      </li>
      <li>
        <b>Fire</b> home, vacant homes, secondary homes, specialty homes, mobile
        homes
      </li>
      <li>
        <b>Life</b> term, universal, index universal, family, children's, key
        employee
      </li>
      <li>
        <b>Commercial</b> contractors, restaurants, retail, gas industry,
        service industry
      </li>
    </ul>
    <aside>
      <i
        >Call today to speak with an independent insurance agent about the
        insurance policies that are right for you.</i
      >
    </aside>
  </mat-card-content>
</mat-card>
