import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactService } from './services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @Input()
  form: FormGroup;

  contacts: string[] = [];

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService
  ) {
    this.form = this.fb.group({});
   }

  ngOnInit(): void {
    this.getContacts();
  }

  getContacts = () => {
    this.contactService.getContacts()
    .subscribe(res => {
      this.contacts = res;
    })
  }
}
