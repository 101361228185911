<div>
  <img src="../../assets/dklogo_macbook_view.jpg">
</div>
<mat-card>
  <mat-card-title>Quote Yourself</mat-card-title>
  <aside><i>Coming soon!</i></aside>
  <mat-card-content>
    <p>
      Check back shortly for DK quoting details. Thanks for your patience.
    </p>
  </mat-card-content>
</mat-card>
