<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>DK Insurance</span>
    <span class="icon-spacer"></span>
    <button mat-button [matMenuTriggerFor]="aboveMenu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <button mat-menu-item>
        <a routerLink="/" routerLinkActive="active">Home</a>
      </button>
      <button mat-menu-item>
        <a routerLink="/quotes" routerLinkActive="active">Quotes</a>
      </button>
      <button mat-menu-item>
        <a routerLink="/services" routerLinkActive="active">Services</a>
      </button>
      <button mat-menu-item>
        <!-- <a routerLink="/contact" routerLinkActive="active">Contact</a> -->
        <a href="mailto: dawnkoehlerins@frontier.com">Contact</a>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>

<footer>
  <div>Contact Us</div>
  <div>240 Main Street, Great Bend, PA 18821</div>
  <div>570-879-8810 (call)</div>
  <div>
    <ul>
      <b>Mon:</b>
      9:00 - 5:30
      <b>Tue:</b>
      9:00 - 5:30
      <b>Wed:</b>
      9:00 - 5:30
      <b>Thur:</b>
      9:00 - 5:30
      <b>Fri:</b>
      9:00 - 5:30
      <b>Sat:</b>
      By appointment
      <b>Sun:</b>
      Closed
    </ul>
  </div>
</footer>
