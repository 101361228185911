<mat-card>
  <mat-card-title>Auto Insurance</mat-card-title>
  <h5>Don't Risk Overpaying</h5>
  <aside style="padding: 1em;">
    <i>Comprehensive auto insurance in Great Bend, PA</i>
  </aside>
  <mat-card-content>
    <p>
      Valid auto insurance is required to drive in Pennsylvania - it's the
      law. But quality auto insurance should do a lot more than meet minimum
      state requirements. At Dawn Koehler Insurance Agency we offer many
      different policy options for personal and commercial auto insurance
      coverage or a more comprehensive planm we will find the best policy for
      you needs and budget. Call today to speak with an independent insurance
      agent about auto insurance in Susquehanna County, PA and Broome County, NY.
    </p>
    <aside style="padding: 1em;">
      <i>Imperfect driving records, multiple vehicles and commercial cars welcome.</i>
    </aside>
    <p>
      Rely on our independent agents to find the best auto insurance policy for your
      specific needs. By working with a variety of carriers, we can customize your
      policy and create one that offers the best coverage at the lowest price available.
      Call today to speak with a licensed insurance agent in Susquehanna County, PA and
      Broome County, NY about drafting the right auto insurance policy for your converage
      needs.
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Personal Insurance</mat-card-title>
  <h5>Relax knowing that you're protected!</h5>
  <aside style="padding: 1em;">
    <i>Umbrella coverage for auto, home, renters, fire and more.</i>
  </aside>
  <mat-card-content>
    <p>
      As we all know, life can be unpredictable. Having a lifeline to protect you and your
      family from the unexpected is a must in today's society. Would you be covered if:
    </p>
    <ul>
      <li>the family pet injures a neighbor?</li>
      <li>a tree on your property falls into a nearby house?</li>
      <li>a child is hurt while visiting your house?</li>
    </ul>
    <p>
      While you have homeowners insurance, it will not always be sufficient to cover such
      unforeseeable events. Umbrella coverage policies provide that extended protection needed
      to go above and beyond your basic home or auto insurance policy in case you run into an
      unexpected situation.
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Fire Insurance</mat-card-title>
  <h5>Don't Allow A Fire to Take Everything</h5>
  <aside style="padding: 1em;">
    <i>Fire insurance for your Susquehanna County, PA and Broome County, NY. Primary and
      secondary properties.
    </i>
  </aside>
  <mat-card-content>
    <p>
      Most property owners don't realize the importance of an effective fire insurance policy
      to cover your home, rental property or business. On average, there are over 375,000
      residential fires each year in the United States resulting in more than $7 billion in
      property losses.
    </p>
    <p>
      Our goal is to protect you and your property in case of a fire related disaster. We will make
      sure that your policy is sufficent to help you get back on your feet after experiencing a fire.
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Business Insurance</mat-card-title>
  <h5>Is Your Business Covered?</h5>
  <aside style="padding: 1em;">
    <i>Commercial insurance plans in Great Bend, PA.</i>
  </aside>
  <mat-card-content>
    <p>
      Insurance is vital for protecting against the mishaps that affect your personal life, but what
      about unexpected events that impact your business? Dawn Koehler Insurance Agency offers
      comprehensive commercial insurance plans for business leaders in all types of industries. Call
      today to speak with a professional insurance agent about commercial coverage including:
    <ul>
      <li>Liability insurance</li>
      <li>Medical insurance</li>
      <li>Business income insurance</li>
      <li>Commercial auto insurance</li>
      <li>Property insurance</li>
    </ul>
    <p>
      Dawn Koehler Insurance Agency can develop an affordable, comprehensive policy for contractors and
      proprietors of retail stores, gas industries, service industries, restaurants and more. Call today
      to learn about all the coverage options for your business.
    </p>
  </mat-card-content>
</mat-card>
