<form [formGroup]="form">
  <mat-form-field class="example-form-field">
    <mat-label>{{contacts}}</mat-label>
    <input matInput type="text">
    <button
      mat-raised-button
      color="primary"
    >Send
    </button>
  </mat-form-field>
</form>
